/* eslint-disable */
import redWines from "../../../data/global/red-wine-es.json";
import { FormattedMessage, FormattedNumber } from "react-intl";
import Icon from "@mdi/react";
import { mdiGlassWine, mdiBottleWine } from "@mdi/js";

import "./RedWines.scss";

const RedWines = () => {
  return (
    <div className="red-wines-container">
      <h2 className="red-wine-title">
        <FormattedMessage id="app.red-wine.title" defaultMessage="Red Wines" />
      </h2>
      {redWines?.entries.map((entry, i) => (
        <div className="red-wine-entry" key={`red-wines-entry-${i}`}>
          <h3 className="red-wine-entry-title">{entry.origin}</h3>
          {entry.list.map((wine, i) => (
            <div className="red-wine-item" key={`red-wines-${i}`}>
              <div className="red-wine-details-container">
                <div>{wine.name}</div>
                <div className="red-wine-details">
                  <p className="red-wine-volume">{wine.volume}</p>
                  <p>{wine.type}</p>
                  <p>{wine.aged}</p>
                  <p>{wine.year}</p>
                </div>
              </div>
              <div className="red-wine-prices">
                {wine.price.glass && (
                  <div className="red-wine-price">
                    <Icon
                      className="red-wine-icon"
                      path={mdiGlassWine}
                      size={1}
                    />
                    <span className="red-wine-money">
                      <FormattedNumber
                        value={wine.price.glass}
                        style="currency"
                        currency="EUR"
                      />
                    </span>
                  </div>
                )}
                <div className="red-wine-price">
                  <Icon
                    className="red-wine-icon"
                    path={mdiBottleWine}
                    size={1}
                  />
                  <span className="red-wine-money">
                    <FormattedNumber
                      value={wine.price.bottle}
                      style="currency"
                      currency="EUR"
                    />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default RedWines;
