import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import "../styles/menu.scss";

const Menu = () => {
  return (
    <div className="menu-container">
      <Link className="menu-item" to="/food">
        <FormattedMessage id="app.menu.food" defaultMessage="Food" />
      </Link>
      <Link className="menu-item" to="/drinks">
        <FormattedMessage id="app.menu.drinks" defaultMessage="Drinks" />
      </Link>
      <Link className="menu-item" to="/wines">
        <FormattedMessage
          id="app.menu.white-wines"
          defaultMessage="White Wines"
        />
      </Link>
      {/* <Link className="menu-item" to="/red-wines">
        <FormattedMessage id="app.menu.red-wines" defaultMessage="Red Wines" />
      </Link> */}
      <div className="menu-info">
        <p className="menu-info-item">
          <span className="menu-info-title">
            <FormattedMessage
              id="app.menu.bread"
              defaultMessage="Bread basket per person"
            />
          </span>
          <span className="menu-info-price">
            <FormattedMessage
              id="app.menu.bread-price"
              defaultMessage="€3.00"
            />
          </span>
        </p>
        <p className="menu-info-item">
          <span className="menu-info-title">
            <FormattedMessage
              id="app.menu.bread-gluten"
              defaultMessage="Gluten free bread - price per piece"
            />
          </span>
          <span className="menu-info-price">
            <FormattedMessage
              id="app.menu.bread-gluten-price"
              defaultMessage="€3.00"
            />
          </span>
        </p>
        <p className="menu-legend-item">
          <p className="menu-legend">
            <FormattedMessage
              id="app.menu.legend"
              defaultMessage="Some of these dishes can be adapted for vegetarian, vegan people with any intolerance of food."
            />
          </p>
          <p className="menu-legend">
            <FormattedMessage
              id="app.menu.legend-2"
              defaultMessage="Ask the waiter/waitress."
            />
          </p>
        </p>
        <p className="menu-info-item">
          <span className="menu-info-title">
            <FormattedMessage
              id="app.menu.vat-included"
              defaultMessage="VAT included"
            />
          </span>
        </p>
      </div>
    </div>
  );
};

export default Menu;
