import {
  mdiCarArrowRight,
  mdiInstagram,
  mdiCalendar,
  mdiPhoneClassic,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { CONTACT_LINKS, FOOTER_INFO } from "./Footer.constants";

import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-icon-container">
        <Link to={CONTACT_LINKS.instagram} target="_blank">
          <Icon className="footer-icon" path={mdiInstagram} size={1} />
        </Link>
        <Link to={CONTACT_LINKS.bookATableGoogle} target="_blank">
          <Icon className="footer-icon" path={mdiCalendar} size={1} />
        </Link>
        <Link to={CONTACT_LINKS.driveMapsAddress} target="_blank">
          <Icon className="footer-icon" path={mdiCarArrowRight} size={1} />
        </Link>
      </div>
      <p className="footer-info-container">
        <span>{FOOTER_INFO.address}</span>
      </p>
      <Link className="footer-info-container" to={`tel:${FOOTER_INFO.phone}`}>
        <Icon path={mdiPhoneClassic} size={1} />
        <FormattedMessage id="app.footer.phone" defaultMessage="Phone:" />{" "}
        <span>{FOOTER_INFO.phone}</span>
      </Link>
    </footer>
  );
};

export default Footer;
