import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import Spanish from "../../lang/es.json";
import English from "../../lang/en.json";

export const LanguageSelectorWrapperContext = React.createContext(null);

const LanguageSelectorWrapper = (props) => {
  const [locale, setLocale] = useState("es");
  const [messages, setMessages] = useState(Spanish);

  function selectLanguage(lang) {
    setLocale(lang);
    setMessages(lang === "en" ? English : lang === "es" ? Spanish : Spanish);
  }

  return (
    <LanguageSelectorWrapperContext.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </LanguageSelectorWrapperContext.Provider>
  );
};

export default LanguageSelectorWrapper;
