import { FormattedMessage } from "react-intl";

import banner480 from "../assets/lgr-banner480.jpg";
import banner1024 from "../assets/lgr-banner1024.jpg";
import banner2048 from "../assets/lgr-banner2048.jpg";

import "../styles/home.scss";

const Home = () => {
  return (
    <main className="main-home">
      <div>
        <picture>
          <source
            media="(-webkit-min-device-pixel-ratio: 1.5)"
            srcSet={`${banner1024} 800w, ${banner2048} 1200w, ${banner2048} 1598w`}
            sizes="
      (min-width: 1066px) 743px,
      (min-width: 800px) calc(75vw - 57px),
      100vw
    "
          />
          <img
            className="banner"
            src={banner480}
            width="480"
            alt="La Gallineta Restaurante Banner"
          />
        </picture>
      </div>
      <div className="home-content">
        <h1>
          <FormattedMessage
            id="app.home.title"
            defaultMessage="La Gallineta Restaurante"
          />
        </h1>
        <h2>
          <FormattedMessage id="app.home.welcome" defaultMessage="Welcome" />
        </h2>
        <h4>
          <FormattedMessage id="app.home.about-us" defaultMessage="About Us" />
        </h4>
        <article>
          <p>
            <FormattedMessage
              id="app.home.intro.1"
              defaultMessage="app.home.intro.1"
            />
          </p>
          <p>
            <FormattedMessage
              id="app.home.intro.2"
              defaultMessage="app.home.intro.2"
            />
          </p>
          <p>
            <FormattedMessage
              id="app.home.intro.3"
              defaultMessage="app.home.intro.3"
            />
          </p>
          <p>
            <FormattedMessage
              id="app.home.intro.4"
              defaultMessage="app.home.intro.4"
            />
          </p>
          <p>
            <FormattedMessage
              id="app.home.intro.5"
              defaultMessage="app.home.intro.5"
            />
          </p>
        </article>
      </div>
    </main>
  );
};

export default Home;
