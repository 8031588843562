/* eslint-disable */
import { useContext } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { LanguageSelectorWrapperContext } from "../../LanguageSelectorWrapper/LanguageSelectorWrapper";
// import sweetWines from "../../../data/global/sweet-wine.json";
// import Icon from "@mdi/react";
// import { mdiGlassWine } from "@mdi/js";
import { ALLERGENS } from "./Food.constants";

import "./Food.scss";

const Food = () => {
  const languageSelectorWrapperContext = useContext(
    LanguageSelectorWrapperContext
  );
  const locale = languageSelectorWrapperContext.locale;

  let food = require(`../../../data/${locale}/food.json`);

  return (
    <>
      <div className="food-container">
        {food?.food?.map((item, i) => (
          <div className="food-item-wrapper" key={`food-item-${i}`}>
            <h2
              className={`food-title ${
                item.subTitle ? "food-title-no-margin" : ""
              }`}
            >
              {item.title}
            </h2>
            {item.subTitle && <p className="food-sub-title">{item.subTitle}</p>}
            {item.dishes.map((dish, i) => (
              <div className="food-item-container" key={`food-dish-${i}`}>
                <div className="food-item-title-wrapper">
                  <p className="food-item-title">{dish.name}</p>
                  <p className="food-item-extra">{dish.extra}</p>
                  <div className="food-allergen-container">
                    {dish.allergens?.map((allergen) => (
                      <img
                        key={`allergen-${allergen}`}
                        className="food-allergen-item"
                        src={ALLERGENS[allergen]}
                        alt={`allergen-${allergen}`}
                      />
                    ))}
                  </div>
                </div>

                <span className="food-price">
                  <FormattedNumber
                    value={dish.price}
                    style="currency"
                    currency="EUR"
                  />
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>

      {/* {sweetWines?.sweetWine && (
        <>
          <hr className="separator" />
          <div className="sweet-wine-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.food.sweet-wines"
                defaultMessage="Sweet Wines"
              />
            </h2>
            {sweetWines.sweetWine.map((wine, i) => (
              <div className="sweet-wine-wrapper" key={`sweet-wine-${i}`}>
                <div className="sweet-wine-item">
                  <p>{wine.name}</p>
                  <div className="sweet-wine-details">
                    <p>{wine.composition}</p>
                    <div className="sweet-wine-extra-info">
                      <span className="sweet-wine-volume">{wine.volume}</span>
                      <span>{wine.origin}</span>
                      <span>{wine.year}</span>
                    </div>
                  </div>
                </div>
                <div className="sweet-wine-price">
                  <Icon path={mdiGlassWine} size={1} />
                  <span>
                    <FormattedNumber
                      value={wine.price}
                      style="currency"
                      currency="EUR"
                    />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </>
      )} */}

      {/* {sweetWines?.fondillones && (
        <>
          <hr className="separator" />
          <div className="sweet-wine-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.food.fondillones"
                defaultMessage="Fondillones"
              />
            </h2>
            {sweetWines.fondillones.map((wine, i) => (
              <div className="sweet-wine-wrapper" key={`sweet-wine-${i}`}>
                <div className="sweet-wine-item">
                  <p>{wine.name}</p>
                  <div className="sweet-wine-details">
                    <p>{wine.composition}</p>
                    <div className="sweet-wine-extra-info">
                      {wine.volume && (
                        <span className="sweet-wine-volume">{wine.volume}</span>
                      )}
                      <span>{wine.origin}</span>
                      <span>{wine.year}</span>
                    </div>
                  </div>
                </div>
                <div className="sweet-wine-price">
                  <Icon path={mdiGlassWine} size={1} />
                  <span>
                    <FormattedNumber
                      value={wine.price}
                      style="currency"
                      currency="EUR"
                    />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </>
      )} */}
    </>
  );
};

export default Food;
