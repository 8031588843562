import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import LanguageSelectorWrapper from "./components/LanguageSelectorWrapper/LanguageSelectorWrapper";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import Menu from "./pages/Menu";
import NoPage from "./pages/NoPage";
import Food from "./components/Menu/Food/Food";
import RedWines from "./components/Menu/RedWines/RedWines";
import WhiteWines from "./components/Menu/WhiteWines/WhiteWines";
import Drinks from "./components/Menu/Drinks/Drinks";

import "./index.css";
import "./styles/css-reset.scss";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return null;
}

export default function App() {
  return (
    <LanguageSelectorWrapper>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="menu" element={<Menu />} />
            <Route path="food" element={<Food />} />
            <Route path="drinks" element={<Drinks />} />
            <Route path="red-wines" element={<RedWines />} />
            <Route path="wines" element={<WhiteWines />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </LanguageSelectorWrapper>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
