import { LanguageSelectorWrapperContext } from "../LanguageSelectorWrapper/LanguageSelectorWrapper";
import { LanguageSelectorModalContext } from "./LanguageSelectorModal";
import { useContext } from "react";
import esFlag from "../../assets/flags/spain_flag.svg";
import enFlag from "../../assets/flags/uk_flag.svg";

import "./LanguageSelectorButton.scss";

const LanguageSelectorButton = () => {
  const languageSelectorWrapperContext = useContext(
    LanguageSelectorWrapperContext
  );
  const languageSelectorModalContext = useContext(LanguageSelectorModalContext);
  let flag = esFlag;

  switch (languageSelectorWrapperContext.locale) {
    case "en":
      flag = enFlag;
      break;
    case "es":
      flag = esFlag;
      break;
    default:
      flag = esFlag;
  }

  return (
    <img
      className="language-selector-button"
      src={flag}
      alt={`flag-${languageSelectorWrapperContext.locale}`}
      onClick={() => languageSelectorModalContext.setShowModal(true)}
    />
  );
};

export default LanguageSelectorButton;
