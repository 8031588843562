import { mdiPhoneClassic, mdiCalendar } from "@mdi/js";
import Icon from "@mdi/react";
// import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
// import {
//   CONTACT_LINKS,
//   FOOTER_INFO,
// } from "../components/Footer/Footer.constants";

import "../styles/contact.scss";

const Contact = () => {
  return (
    <main className="contact-main">
      <h3>
        <FormattedMessage
          id="app.contact.time-table"
          defaultMessage="Opening Hours"
        />
      </h3>
      <article>
        <h4>Preparando la temporada.</h4>
        <h4>Nos vemos en Marzo 2025</h4>
      </article>

      <h3>
        <FormattedMessage
          id="app.contact.reservation-title"
          defaultMessage="Book a table:"
        />
      </h3>
      <div className="reservation-container">
        <div
          className="reservation-item"
        >
          <Icon path={mdiCalendar} size={1} />
          <FormattedMessage
            id="app.contact.reservation-online"
            defaultMessage="Online"
          />
        </div>
        <div
            className="reservation-item"
        >
          <Icon path={mdiPhoneClassic} size={1} />
          <FormattedMessage
            id="app.contact.reservation-call"
            defaultMessage="Call us"
          />
        </div>
      </div>
    </main>
  );
};

export default Contact;
