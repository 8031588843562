import apio from "../../../assets/allergens/apio.svg";
import crabs from "../../../assets/allergens/crabs.svg";
import frutos from "../../../assets/allergens/frutos.svg";
import gluten from "../../../assets/allergens/gluten.svg";
import lacteos from "../../../assets/allergens/lacteos.svg";
import huevos from "../../../assets/allergens/huevos.svg";
import molusco from "../../../assets/allergens/molusco.svg";
import mostaza from "../../../assets/allergens/mostaza.svg";
import pescado from "../../../assets/allergens/pescado.svg";
import sulfitos from "../../../assets/allergens/sulfitos.svg";

export const ALLERGENS = {
  a: apio,
  c: crabs,
  f: frutos,
  l: lacteos,
  h: huevos,
  g: gluten,
  m: molusco,
  o: mostaza,
  p: pescado,
  s: sulfitos,
};
