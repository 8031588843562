/* eslint-disable */
import { FormattedMessage, FormattedNumber } from "react-intl";
import drinks from "../../../data/global/drinks.json";
import Icon from "@mdi/react";
import { mdiGlassWine, mdiBottleWine, mdiCup } from "@mdi/js";

const Drinks = () => {
  return (
    <>
      {drinks?.nonAlcoholicDrinks && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.drinks.non-alcohol"
                defaultMessage="Non Alcoholic"
              />
            </h2>
            {drinks.nonAlcoholicDrinks.map((drink, i) => (
              <div className="sweet-wine-wrapper" key={`drinks-${i}`}>
                <div className="sweet-wine-item">
                  <p>{drink.name}</p>
                  <div className="sweet-wine-details">
                    <p>{drink.capacity}</p>
                  </div>
                </div>
                <div className="sweet-wine-price">
                  <span>
                    <FormattedNumber
                      value={drink.price}
                      style="currency"
                      currency="EUR"
                    />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {drinks?.alcoholicDrinks && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.drinks.alcohol"
                defaultMessage="Alcoholic Drinks"
              />
            </h2>
            {drinks.alcoholicDrinks.map((drink, i) => (
              <div className="sweet-wine-wrapper" key={`alco-drinks-${i}`}>
                <div className="sweet-wine-item">
                  <p>{drink.name}</p>
                  <div className="sweet-wine-details">
                    <p>{drink.capacity}</p>
                  </div>
                </div>
                <div className="sweet-wine-price">
                  <span>
                    <FormattedNumber
                      value={drink.price}
                      style="currency"
                      currency="EUR"
                    />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {drinks?.appetizerDrinks && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.drinks.appetizer"
                defaultMessage="Appetizer Drinks"
              />
            </h2>
            {drinks.appetizerDrinks.map((drink, i) => (
              <div className="sweet-wine-wrapper" key={`appetizer-drinks-${i}`}>
                <div className="sweet-wine-item">
                  <p>{drink.name}</p>
                  <div className="sweet-wine-details drink-capacity">
                    <p>{drink.origin}</p>
                    <p>{drink.capacity}</p>
                    <p>{drink.type}</p>
                    <p>{drink.aged}</p>
                    <p className="sweet-wine-volume">{drink.volume}</p>
                  </div>
                </div>
                <div className="red-wine-prices">
                  {drink.price.glass && (
                    <div className="red-wine-price">
                      <Icon
                        className="red-wine-icon"
                        path={mdiGlassWine}
                        size={1}
                      />
                      <span className="red-wine-money">
                        <FormattedNumber
                          value={drink.price.glass}
                          style="currency"
                          currency="EUR"
                        />
                      </span>
                    </div>
                  )}
                  {drink.price.bottle && (
                    <div className="red-wine-price">
                      <Icon
                        className="red-wine-icon"
                        path={mdiBottleWine}
                        size={1}
                      />
                      <span className="red-wine-money">
                        <FormattedNumber
                          value={drink.price.bottle}
                          style="currency"
                          currency="EUR"
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {drinks?.whiteVermut && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.drinks.white-vermut"
                defaultMessage="White Vermut"
              />
            </h2>
            {drinks.whiteVermut.map((drink, i) => (
              <div
                className="sweet-wine-wrapper"
                key={`white-vermut-drinks-${i}`}
              >
                <div className="sweet-wine-item">
                  <p>{drink.name}</p>
                  <div className="sweet-wine-details drink-capacity">
                    <p>{drink.origin}</p>
                    <p>{drink.capacity}</p>
                    <p className="sweet-wine-volume">{drink.volume}</p>
                  </div>
                </div>
                <div className="red-wine-prices">
                  {drink.price.glass && (
                    <div className="red-wine-price">
                      <Icon className="red-wine-icon" path={mdiCup} size={1} />
                      <span className="red-wine-money">
                        <FormattedNumber
                          value={drink.price.glass}
                          style="currency"
                          currency="EUR"
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {drinks?.redVermut && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.drinks.red-vermut"
                defaultMessage="Red Vermut"
              />
            </h2>
            {drinks.redVermut.map((drink, i) => (
              <div
                className="sweet-wine-wrapper"
                key={`red-vermut-drinks-${i}`}
              >
                <div className="sweet-wine-item">
                  <p>{drink.name}</p>
                  <div className="sweet-wine-details drink-capacity">
                    <p>{drink.extra}</p>
                    <p>{drink.origin}</p>
                    <p>{drink.capacity}</p>
                    <p className="sweet-wine-volume">{drink.volume}</p>
                  </div>
                </div>
                <div className="red-wine-prices">
                  {drink.price.glass && (
                    <div className="red-wine-price">
                      <Icon className="red-wine-icon" path={mdiCup} size={1} />
                      <span className="red-wine-money">
                        <FormattedNumber
                          value={drink.price.glass}
                          style="currency"
                          currency="EUR"
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default Drinks;
