/* eslint-disable */
import wines from "../../../data/global/white-wine-es.json";
import { FormattedMessage, FormattedNumber } from "react-intl";
import Icon from "@mdi/react";
import { mdiGlassWine, mdiBottleWine } from "@mdi/js";

import "./WhiteWines.scss";

const WhiteWines = () => {
  return (
    <>
      {wines?.germanWhites && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.german-wines.title"
                defaultMessage="German White Wines"
              />
            </h2>
            {wines.germanWhites.map((entry, i) => (
              <div className="red-wine-entry" key={`white-wines-entry-${i}`}>
                {/* <h3 className="red-wine-entry-title white-wine-entry-title">
                  {entry.origin}
                </h3> */}
                {entry.list.map((wine, i) => (
                  <div className="red-wine-item" key={`red-wines-${i}`}>
                    <div className="red-wine-details-container">
                      <div>{wine.name}</div>
                      <div className="red-wine-details">
                        <p className="red-wine-volume">{wine.volume}</p>
                        <p>{wine.type}</p>
                        <p>{wine.aged}</p>
                        <p>{wine.year}</p>
                      </div>
                    </div>
                    <div className="red-wine-prices">
                      {wine.price.glass && (
                        <div className="red-wine-price">
                          <Icon
                            className="red-wine-icon"
                            path={mdiGlassWine}
                            size={1}
                          />
                          <span className="red-wine-money">
                            <FormattedNumber
                              value={wine.price.glass}
                              style="currency"
                              currency="EUR"
                            />
                          </span>
                        </div>
                      )}
                      <div className="red-wine-price">
                        <Icon
                          className="red-wine-icon"
                          path={mdiBottleWine}
                          size={1}
                        />
                        <span className="red-wine-money">
                          <FormattedNumber
                            value={wine.price.bottle}
                            style="currency"
                            currency="EUR"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
      {wines?.frenchWhites && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.french-wines.title"
                defaultMessage="French White Wines"
              />
            </h2>
            {wines.frenchWhites.map((entry, i) => (
              <div className="red-wine-entry" key={`white-wines-entry-${i}`}>
                {/* <h3 className="red-wine-entry-title white-wine-entry-title">
                  {entry.origin}
                </h3> */}
                {entry.list.map((wine, i) => (
                  <div className="red-wine-item" key={`red-wines-${i}`}>
                    <div className="red-wine-details-container">
                      <div>{wine.name}</div>
                      <div className="red-wine-details">
                        <p className="red-wine-volume">{wine.volume}</p>
                        <p>{wine.type}</p>
                        <p>{wine.aged}</p>
                        <p>{wine.year}</p>
                      </div>
                    </div>
                    <div className="red-wine-prices">
                      {wine.price.glass && (
                        <div className="red-wine-price">
                          <Icon
                            className="red-wine-icon"
                            path={mdiGlassWine}
                            size={1}
                          />
                          <span className="red-wine-money">
                            <FormattedNumber
                              value={wine.price.glass}
                              style="currency"
                              currency="EUR"
                            />
                          </span>
                        </div>
                      )}
                      <div className="red-wine-price">
                        <Icon
                          className="red-wine-icon"
                          path={mdiBottleWine}
                          size={1}
                        />
                        <span className="red-wine-money">
                          <FormattedNumber
                            value={wine.price.bottle}
                            style="currency"
                            currency="EUR"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
      {wines?.greekWhites && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.greek-wines.title"
                defaultMessage="Greek White Wines"
              />
            </h2>
            {wines.greekWhites.map((entry, i) => (
              <div className="red-wine-entry" key={`white-wines-entry-${i}`}>
                {/* <h3 className="red-wine-entry-title white-wine-entry-title">
                  {entry.origin}
                </h3> */}
                {entry.list.map((wine, i) => (
                  <div className="red-wine-item" key={`red-wines-${i}`}>
                    <div className="red-wine-details-container">
                      <div>{wine.name}</div>
                      <div className="red-wine-details">
                        <p className="red-wine-volume">{wine.volume}</p>
                        <p>{wine.type}</p>
                        <p>{wine.aged}</p>
                        <p>{wine.year}</p>
                      </div>
                    </div>
                    <div className="red-wine-prices">
                      {wine.price.glass && (
                        <div className="red-wine-price">
                          <Icon
                            className="red-wine-icon"
                            path={mdiGlassWine}
                            size={1}
                          />
                          <span className="red-wine-money">
                            <FormattedNumber
                              value={wine.price.glass}
                              style="currency"
                              currency="EUR"
                            />
                          </span>
                        </div>
                      )}
                      <div className="red-wine-price">
                        <Icon
                          className="red-wine-icon"
                          path={mdiBottleWine}
                          size={1}
                        />
                        <span className="red-wine-money">
                          <FormattedNumber
                            value={wine.price.bottle}
                            style="currency"
                            currency="EUR"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
      {wines?.portugueseWhites && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.portuguese-wines.title"
                defaultMessage="Portuguese White Wines"
              />
            </h2>
            {wines.portugueseWhites.map((entry, i) => (
              <div className="red-wine-entry" key={`white-wines-entry-${i}`}>
                {/* <h3 className="red-wine-entry-title white-wine-entry-title">
                  {entry.origin}
                </h3> */}
                {entry.list.map((wine, i) => (
                  <div className="red-wine-item" key={`red-wines-${i}`}>
                    <div className="red-wine-details-container">
                      <div>{wine.name}</div>
                      <div className="red-wine-details">
                        <p className="red-wine-volume">{wine.volume}</p>
                        <p>{wine.type}</p>
                        <p>{wine.aged}</p>
                        <p>{wine.year}</p>
                      </div>
                    </div>
                    <div className="red-wine-prices">
                      {wine.price.glass && (
                        <div className="red-wine-price">
                          <Icon
                            className="red-wine-icon"
                            path={mdiGlassWine}
                            size={1}
                          />
                          <span className="red-wine-money">
                            <FormattedNumber
                              value={wine.price.glass}
                              style="currency"
                              currency="EUR"
                            />
                          </span>
                        </div>
                      )}
                      <div className="red-wine-price">
                        <Icon
                          className="red-wine-icon"
                          path={mdiBottleWine}
                          size={1}
                        />
                        <span className="red-wine-money">
                          <FormattedNumber
                            value={wine.price.bottle}
                            style="currency"
                            currency="EUR"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
      {wines?.southAfricaWhites && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.south-africa-wines.title"
                defaultMessage="South African White Wines"
              />
            </h2>
            {wines.southAfricaWhites.map((entry, i) => (
              <div className="red-wine-entry" key={`white-wines-entry-${i}`}>
                {/* <h3 className="red-wine-entry-title white-wine-entry-title">
                  {entry.origin}
                </h3> */}
                {entry.list.map((wine, i) => (
                  <div className="red-wine-item" key={`red-wines-${i}`}>
                    <div className="red-wine-details-container">
                      <div>{wine.name}</div>
                      <div className="red-wine-details">
                        <p className="red-wine-volume">{wine.volume}</p>
                        <p>{wine.type}</p>
                        <p>{wine.aged}</p>
                        <p>{wine.year}</p>
                      </div>
                    </div>
                    <div className="red-wine-prices">
                      {wine.price.glass && (
                        <div className="red-wine-price">
                          <Icon
                            className="red-wine-icon"
                            path={mdiGlassWine}
                            size={1}
                          />
                          <span className="red-wine-money">
                            <FormattedNumber
                              value={wine.price.glass}
                              style="currency"
                              currency="EUR"
                            />
                          </span>
                        </div>
                      )}
                      <div className="red-wine-price">
                        <Icon
                          className="red-wine-icon"
                          path={mdiBottleWine}
                          size={1}
                        />
                        <span className="red-wine-money">
                          <FormattedNumber
                            value={wine.price.bottle}
                            style="currency"
                            currency="EUR"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
      {wines?.whites && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.white-wines.title"
                defaultMessage="White Wines"
              />
            </h2>
            {wines.whites.map((entry, i) => (
              <div className="red-wine-entry" key={`white-wines-entry-${i}`}>
                <h3 className="red-wine-entry-title white-wine-entry-title">
                  {entry.origin}
                </h3>
                {entry.list.map((wine, i) => (
                  <div className="red-wine-item" key={`red-wines-${i}`}>
                    <div className="red-wine-details-container">
                      <div>{wine.name}</div>
                      <div className="red-wine-details">
                        <p className="red-wine-volume">{wine.volume}</p>
                        <p>{wine.type}</p>
                        <p>{wine.aged}</p>
                        <p>{wine.year}</p>
                      </div>
                    </div>
                    <div className="red-wine-prices">
                      {wine.price.glass && (
                        <div className="red-wine-price">
                          <Icon
                            className="red-wine-icon"
                            path={mdiGlassWine}
                            size={1}
                          />
                          <span className="red-wine-money">
                            <FormattedNumber
                              value={wine.price.glass}
                              style="currency"
                              currency="EUR"
                            />
                          </span>
                        </div>
                      )}
                      <div className="red-wine-price">
                        <Icon
                          className="red-wine-icon"
                          path={mdiBottleWine}
                          size={1}
                        />
                        <span className="red-wine-money">
                          <FormattedNumber
                            value={wine.price.bottle}
                            style="currency"
                            currency="EUR"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
      {wines?.rose && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.rose-wines.title"
                defaultMessage="Rose Wines"
              />
            </h2>
            {wines.rose.map((entry, i) => (
              <div className="red-wine-entry" key={`white-wines-entry-${i}`}>
                <h3 className="red-wine-entry-title white-wine-entry-title">
                  {entry.origin}
                </h3>
                {entry.list.map((wine, i) => (
                  <div className="red-wine-item" key={`red-wines-${i}`}>
                    <div className="red-wine-details-container">
                      <div>{wine.name}</div>
                      <div className="red-wine-details">
                        <p className="red-wine-volume">{wine.volume}</p>
                        <p>{wine.type}</p>
                        <p>{wine.aged}</p>
                        <p>{wine.year}</p>
                      </div>
                    </div>
                    <div className="red-wine-prices">
                      {wine.price.glass && (
                        <div className="red-wine-price">
                          <Icon
                            className="red-wine-icon"
                            path={mdiGlassWine}
                            size={1}
                          />
                          <span className="red-wine-money">
                            <FormattedNumber
                              value={wine.price.glass}
                              style="currency"
                              currency="EUR"
                            />
                          </span>
                        </div>
                      )}
                      <div className="red-wine-price">
                        <Icon
                          className="red-wine-icon"
                          path={mdiBottleWine}
                          size={1}
                        />
                        <span className="red-wine-money">
                          <FormattedNumber
                            value={wine.price.bottle}
                            style="currency"
                            currency="EUR"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
      {wines?.cavas && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.cavas.title"
                defaultMessage="Sparkling Wines, Cavas and Champagne"
              />
            </h2>
            {wines.cavas.map((entry, i) => (
              <div className="red-wine-entry" key={`white-wines-entry-${i}`}>
                {entry.list.map((wine, i) => (
                  <div className="red-wine-item" key={`red-wines-${i}`}>
                    <div className="red-wine-details-container">
                      <div>{wine.name}</div>
                      <div className="red-wine-details">
                        <p className="red-wine-volume">{wine.volume}</p>
                        <p>{wine.type}</p>
                        <p>{wine.aged}</p>
                        <p>{wine.year}</p>
                      </div>
                    </div>
                    <div className="red-wine-prices">
                      {wine.price.glass && (
                        <div className="red-wine-price">
                          <Icon
                            className="red-wine-icon"
                            path={mdiGlassWine}
                            size={1}
                          />
                          <span className="red-wine-money">
                            <FormattedNumber
                              value={wine.price.glass}
                              style="currency"
                              currency="EUR"
                            />
                          </span>
                        </div>
                      )}
                      <div className="red-wine-price">
                        <Icon
                          className="red-wine-icon"
                          path={mdiBottleWine}
                          size={1}
                        />
                        <span className="red-wine-money">
                          <FormattedNumber
                            value={wine.price.bottle}
                            style="currency"
                            currency="EUR"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
      {wines?.orangeWines && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.orange-wine.title"
                defaultMessage="Orange Wine"
              />
            </h2>
            {wines.orangeWines.map((entry, i) => (
              <div className="red-wine-entry" key={`white-wines-entry-${i}`}>
                {entry.list.map((wine, i) => (
                  <div className="red-wine-item" key={`red-wines-${i}`}>
                    <div className="red-wine-details-container">
                      <div>{wine.name}</div>
                      <div className="red-wine-details">
                        <p className="red-wine-volume">{wine.volume}</p>
                        <p>{wine.type}</p>
                        <p>{wine.aged}</p>
                        <p>{wine.year}</p>
                      </div>
                    </div>
                    <div className="red-wine-prices">
                      {wine.price.glass && (
                        <div className="red-wine-price">
                          <Icon
                            className="red-wine-icon"
                            path={mdiGlassWine}
                            size={1}
                          />
                          <span className="red-wine-money">
                            <FormattedNumber
                              value={wine.price.glass}
                              style="currency"
                              currency="EUR"
                            />
                          </span>
                        </div>
                      )}
                      <div className="red-wine-price">
                        <Icon
                          className="red-wine-icon"
                          path={mdiBottleWine}
                          size={1}
                        />
                        <span className="red-wine-money">
                          <FormattedNumber
                            value={wine.price.bottle}
                            style="currency"
                            currency="EUR"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
      {wines?.australianReds && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.australia-red-wines.title"
                defaultMessage="Australian Red Wines"
              />
            </h2>
            {wines.australianReds.map((entry, i) => (
              <div className="red-wine-entry" key={`white-wines-entry-${i}`}>
                {/* <h3 className="red-wine-entry-title white-wine-entry-title">
                  {entry.origin}
                </h3> */}
                {entry.list.map((wine, i) => (
                  <div className="red-wine-item" key={`red-wines-${i}`}>
                    <div className="red-wine-details-container">
                      <div>{wine.name}</div>
                      <div className="red-wine-details">
                        <p className="red-wine-volume">{wine.volume}</p>
                        <p>{wine.type}</p>
                        <p>{wine.aged}</p>
                        <p>{wine.year}</p>
                      </div>
                    </div>
                    <div className="red-wine-prices">
                      {wine.price.glass && (
                        <div className="red-wine-price">
                          <Icon
                            className="red-wine-icon"
                            path={mdiGlassWine}
                            size={1}
                          />
                          <span className="red-wine-money">
                            <FormattedNumber
                              value={wine.price.glass}
                              style="currency"
                              currency="EUR"
                            />
                          </span>
                        </div>
                      )}
                      <div className="red-wine-price">
                        <Icon
                          className="red-wine-icon"
                          path={mdiBottleWine}
                          size={1}
                        />
                        <span className="red-wine-money">
                          <FormattedNumber
                            value={wine.price.bottle}
                            style="currency"
                            currency="EUR"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
      {wines?.red && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.red-wines.title"
                defaultMessage="Red Wines"
              />
            </h2>
            {wines.red.map((entry, i) => (
              <div className="red-wine-entry" key={`white-wines-entry-${i}`}>
                <h3 className="red-wine-entry-title white-wine-entry-title">
                  {entry.origin}
                </h3>
                {entry.list.map((wine, i) => (
                  <div className="red-wine-item" key={`red-wines-${i}`}>
                    <div className="red-wine-details-container">
                      <div>{wine.name}</div>
                      <div className="red-wine-details">
                        <p className="red-wine-volume">{wine.volume}</p>
                        <p>{wine.type}</p>
                        <p>{wine.aged}</p>
                        <p>{wine.year}</p>
                      </div>
                    </div>
                    <div className="red-wine-prices">
                      {wine.price.glass && (
                        <div className="red-wine-price">
                          <Icon
                            className="red-wine-icon"
                            path={mdiGlassWine}
                            size={1}
                          />
                          <span className="red-wine-money">
                            <FormattedNumber
                              value={wine.price.glass}
                              style="currency"
                              currency="EUR"
                            />
                          </span>
                        </div>
                      )}
                      <div className="red-wine-price">
                        <Icon
                          className="red-wine-icon"
                          path={mdiBottleWine}
                          size={1}
                        />
                        <span className="red-wine-money">
                          <FormattedNumber
                            value={wine.price.bottle}
                            style="currency"
                            currency="EUR"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
      {wines?.sweetWine && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.sweet-wines.title"
                defaultMessage="Sweet Wines"
              />
            </h2>
            {wines.sweetWine.map((entry, i) => (
              <div className="red-wine-entry" key={`white-wines-entry-${i}`}>
                {/* <h3 className="red-wine-entry-title white-wine-entry-title">
                  {entry.origin}
                </h3> */}
                {entry.list.map((wine, i) => (
                  <div className="red-wine-item" key={`red-wines-${i}`}>
                    <div className="red-wine-details-container">
                      <div>{wine.name}</div>
                      <div className="red-wine-details">
                        <p className="red-wine-volume">{wine.volume}</p>
                        <p>{wine.type}</p>
                        <p>{wine.aged}</p>
                        <p>{wine.year}</p>
                      </div>
                    </div>
                    <div className="red-wine-prices">
                      {wine.price && (
                        <div className="red-wine-price">
                          <Icon
                            className="red-wine-icon"
                            path={mdiGlassWine}
                            size={1}
                          />
                          <span className="red-wine-money">
                            <FormattedNumber
                              value={wine.price}
                              style="currency"
                              currency="EUR"
                            />
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
      {wines?.fondillones && (
        <>
          <div className="sweet-wine-container drinks-container">
            <h2 className="sweet-wine-title">
              <FormattedMessage
                id="app.fondillones-wines.title"
                defaultMessage="Fondillones"
              />
            </h2>
            {wines.fondillones.map((entry, i) => (
              <div className="red-wine-entry" key={`white-wines-entry-${i}`}>
                {/* <h3 className="red-wine-entry-title white-wine-entry-title">
                  {entry.origin}
                </h3> */}
                {entry.list.map((wine, i) => (
                  <div className="red-wine-item" key={`red-wines-${i}`}>
                    <div className="red-wine-details-container">
                      <div>{wine.name}</div>
                      <div className="red-wine-details">
                        <p className="red-wine-volume">{wine.volume}</p>
                        <p>{wine.type}</p>
                        <p>{wine.aged}</p>
                        <p>{wine.year}</p>
                      </div>
                    </div>
                    <div className="red-wine-prices">
                      {wine.price && (
                        <div className="red-wine-price">
                          <Icon
                            className="red-wine-icon"
                            path={mdiGlassWine}
                            size={1}
                          />
                          <span className="red-wine-money">
                            <FormattedNumber
                              value={wine.price}
                              style="currency"
                              currency="EUR"
                            />
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default WhiteWines;
