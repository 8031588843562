import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import LanguageSelectorButton from "../LanguageSelector/LanguageSelectorButton";

import logo from "../../assets/lgr-logo.png";

import "./Navigation.scss";

const Navigation = () => {
  return (
    <header className="header">
      <NavLink className="logo-container" to="/">
        <img className="logo" src={logo} alt="La Gallineta Restaurante" />
      </NavLink>
      <nav className="navigation">
        <ul className="navigation-list">
          <li className="navigation-list-item">
            <NavLink
              to="/"
              className={({ isActive, _ }) => (isActive ? "active-link" : "")}
            >
              <FormattedMessage
                id="app.navigation.home"
                defaultMessage="Home"
              />
            </NavLink>
          </li>
          <li className="navigation-list-item">
            <NavLink
              to="/menu"
              className={({ isActive, _ }) => (isActive ? "active-link" : "")}
            >
              <FormattedMessage
                id="app.navigation.menu"
                defaultMessage="Menu"
              />
            </NavLink>
          </li>
          <li className="navigation-list-item">
            <NavLink
              to="/contact"
              className={({ isActive, _ }) => (isActive ? "active-link" : "")}
            >
              <FormattedMessage
                id="app.navigation.contact"
                defaultMessage="Contact"
              />
            </NavLink>
          </li>
        </ul>
      </nav>
      <LanguageSelectorButton />
    </header>
  );
};

export default Navigation;
