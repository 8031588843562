export const CONTACT_LINKS = {
  instagram: "https://www.instagram.com/restaurantelagallineta/",
  bookATableGoogle:
    "http://lagallinetaalmeria.com",
  driveMapsAddress: "https://goo.gl/maps/VJiQAnZgz4w95GK39",
};

export const FOOTER_INFO = {
  address:
    "La Gallineta Restaurante - Carr. Níjar-San José, 20, 04117 El Pozo de los Frailes, Almería, Spain",
  phone: "+34 646 51 58 68",
};
