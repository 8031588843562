import { mdiCloseThick } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useContext, useState, useEffect } from "react";
import esFlag from "../../assets/flags/spain_flag.svg";
import enFlag from "../../assets/flags/uk_flag.svg";
import { LanguageSelectorWrapperContext } from "../LanguageSelectorWrapper/LanguageSelectorWrapper";

import "./LanguageSelectorModal.scss";

export const LanguageSelectorModalContext = React.createContext(null);

const LanguageSelectorModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const context = useContext(LanguageSelectorWrapperContext);

  function onCloseModal() {
    setShowModal(false);
  }

  function onSelectLanguage(lang) {
    context.selectLanguage(lang);
    onCloseModal();
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [context.locale]);

  return (
    <LanguageSelectorModalContext.Provider value={{ showModal, setShowModal }}>
      <div
        className={`language-selector-modal ${
          showModal ? "language-selector-modal--active" : ""
        }`}
      >
        <Icon
          className="language-selector-close-button"
          path={mdiCloseThick}
          size={1}
          onClick={onCloseModal}
        />
        <div>
          <p
            className="language-selector-item"
            onClick={() => onSelectLanguage("es")}
          >
            <img
              className="language-selector-button"
              src={esFlag}
              alt={`flag-es`}
            />
            <span>Español</span>
          </p>
          <p
            className="language-selector-item"
            onClick={() => onSelectLanguage("en")}
          >
            <img
              className="language-selector-button"
              src={enFlag}
              alt={`flag-en`}
            />
            <span>English</span>
          </p>
        </div>
      </div>
      {props.children}
    </LanguageSelectorModalContext.Provider>
  );
};

export default LanguageSelectorModal;
