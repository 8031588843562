import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Icon from "@mdi/react";
import { mdiSilverwareForkKnife } from "@mdi/js";

import "../styles/nopage.scss";

const NoPage = () => {
  return (
    <div className="not-found-container">
      <NavLink className="not-found-button" to="/menu">
        <Icon path={mdiSilverwareForkKnife} size={3} />
        <p>
          <FormattedMessage
            id="app.404.page-not-found"
            defaultMessage="Page not found"
          />
        </p>
      </NavLink>
    </div>
  );
};

export default NoPage;
