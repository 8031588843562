import { Outlet } from "react-router-dom";
import Navigation from "../components/Navigation/Navigation";
import Footer from "../components/Footer/Footer";
import LanguageSelectorModal from "../components/LanguageSelector/LanguageSelectorModal";

import "../styles/layout.scss";

const Layout = () => {
  return (
    <LanguageSelectorModal>
      <Navigation />
      <div className="content">
        <Outlet />
      </div>
      <Footer />
    </LanguageSelectorModal>
  );
};

export default Layout;
